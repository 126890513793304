<template>
  <div class="menu__wrap">
    <ul>
      <li>
        <router-link :to="{ name: 'Index' }">
          <div class="menu__box">
            <div class="icon home">
              <img src="~@/assets/images/menu-icon00.png" alt="">
            </div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/life/" active-class="active">
          <div class="menu__box">
            <div class="icon">
              <div class="dft"><img src="~@/assets/images/menu-icon02.png" alt=""></div>
              <div class="ov"><img src="~@/assets/images/menu-icon02-ov.png" alt=""></div>
            </div>
            <div class="title">{{ $t('menu-1') }} </div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/diagnosis/" active-class="active">
          <div class="menu__box">
            <div class="icon">
              <div class="dft"><img src="~@/assets/images/notice_off.png" alt=""></div>
              <div class="ov"><img src="~@/assets/images/notice_on.png" alt=""></div>
            </div>
            <div class="title">{{ $t('menu-2') }}</div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/schedule/" active-class="active">
          <div class="menu__box">
            <div class="icon">
              <div class="dft"><img src="~@/assets/images/menu-icon04.png" alt=""></div>
              <div class="ov"><img src="~@/assets/images/menu-icon04-ov.png" alt=""></div>
            </div>
            <div class="title">{{ $t('menu-3') }}</div>
          </div>
        </router-link>
      </li>
      <li>
        <router-link to="/member" active-class="active">
          <div class="menu__box">
            <div class="icon">
              <div class="dft"><img src="~@/assets/images/setting_on.png" alt=""></div>
              <div class="ov"><img src="~@/assets/images/setting_off.png" alt=""></div>
            </div>
            <div class="title">{{ $t('menu-4') }}</div>
          </div>
        </router-link>
      </li>
      <!-- <li class="woori">
        <div class="woori-logo">
          <img src="@/assets/images/woori.png">
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'menu-view',
  components: {
  },
  data() {
    return {
      test: true
    }

  },
  methods: {
    preparing() {
      this.$message('준비중입니다.');
    }
  }
}
</script>