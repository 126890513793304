import Vue from 'vue';
import App from './App.vue';
import router from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueApexCharts from 'vue-apexcharts';
// import locale from 'element-ui/lib/locale/lang/ko';
import vueCookies from "vue-cookies";
import axios from "axios";
import Vue2Editor from "vue2-editor";
import VueI18n from 'vue-i18n';
import messages from './i18n/index';

import('./assets/css/common.css');
import('./assets/css/default.css');
import('./assets/css/header.css');
import('./assets/css/jinmyeong.css');

Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.use(vueCookies);
Vue.use(Vue2Editor);
Vue.use(VueI18n);

Vue.prototype.$axios = axios.create({
  // baseURL: "https://org.peachbite.co.kr/api",
  headers: {
    'Access-Control-Allow-Credentials': true,
    'ngrok-skip-browser-warning': true,
  },
  // baseURL: "http://localhost:8000/api"
});

new Vue({
  router,
  i18n: new VueI18n({
    legacy: false,
    locale: 'CN',
    //fallbackLocale: 'CN',
    messages : messages
  }),
  render: h => h(App)
}).$mount('#app')