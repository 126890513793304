<template>
  <div id="app">
    <header-view v-if="showLayout" />
    <menu-view v-if="showLayout" />
    <router-view/>
  </div>
</template>

<script>
import HeaderView from './layout/Header'
import MenuView from './layout/Menu'

export default {
  components: {
    HeaderView,
    MenuView,
  },
  data() {
    return {
      lastErrorTime: Date.now(),
      showLayout: false,
    };
  },
  watch: {
    $route: 'updateLayout'
  },
  mounted() {
    // 재 배포시 청크 로드 오류 처리
    this.$router.onError((error) => {
      if (error.name === 'ChunkLoadError') {
        const currentTime = Date.now();
        if(currentTime - this.lastErrorTime >= 3000) {
          this.lastErrorTime = currentTime;
          window.location.reload(true);
        }
      }
    });
  },
  methods: {
    updateLayout() {
      this.showLayout = this.$route.meta.layout !== false;
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>