import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Index",
    component: () => import(/* webpackChunkName: "home" */ "@/views/index.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/login.vue"),
    meta: { layout: false },
  },
  {
    path: "/login",
    name: "LoginLayout",
    component: () => import(/* webpackChunkName: "login_layout" */ "@/layout/login.vue"),
    meta: { layout: false },
    children: [
      {
        path: "id_lost",
        name: "IdLost",
        component: () => import(/* webpackChunkName: "id_lost" */ "@/views/login/id_lost.vue"),
        meta: { layout: false },
      },
      {
        path: "password_lost",
        name: "PasswordLost",
        component: () => import(/* webpackChunkName: "password_lost" */ "@/views/login/password_lost.vue"),
        meta: { layout: false },
      },
      {
        path: "register",
        name: "Register",
        component: () => import(/* webpackChunkName: "register" */ "@/views/login/register.vue"),
        meta: { layout: false },
      },
    ],
  },
  {
    path: "/life",
    name: "LifeIndex",
    component: () => import(/* webpackChunkName: "life" */ "@/views/life/index.vue"),
  },
  {
    path: "/life/student_view",
    name: "StudentView",
    component: () => import(/* webpackChunkName: "student_view" */ "@/views/life/student_view.vue"),
  },
  {
    path: "/member",
    name: "MemberIndex",
    component: () => import(/* webpackChunkName: "member" */ "@/views/member/index.vue"),
  },
  {
    path: "/student",
    name: "StudentList",
    component: () => import(/* webpackChunkName: "student" */ "@/views/student/index.vue"),
  },
  {
    path: "/student/student_update",
    name: "StudentUpdate",
    component: () => import(/* webpackChunkName: "student_update" */ "@/views/student/student_update.vue"),
  },
  {
    path: "/schedule",
    name: "ScheduleIndex",
    component: () => import(/* webpackChunkName: "schedule" */ "@/views/schedule/index.vue"),
  },
  {
    path: "/pay",
    name: "PayIndex",
    component: () => import(/* webpackChunkName: "pay" */ "@/views/pay/index.vue"),
  },
  {
    path: "/community",
    name: "CommunityIndex",
    component: () => import(/* webpackChunkName: "community" */ "@/views/community/index.vue"),
  },
  {
    path: "/community/faq",
    name: "CommunityFAQ",
    component: () => import(/* webpackChunkName: "faq" */ "@/views/community/faq.vue"),
  },
  {
    path: "/community/inquiry",
    name: "CommunityInquiry",
    component: () => import(/* webpackChunkName: "inquiry" */ "@/views/community/inquiry.vue"),
  },
  {
    path: "/setting",
    name: "계정관리",
    component: () => import(/* webpackChunkName: "setting" */ "@/views/setting/index.vue"),
  },
  {
    path: "/setting/notice",
    name: "SettingNotice",
    component: () => import(/* webpackChunkName: "notice" */ "@/views/setting/notice.vue"),
  },
  {
    path: "/setting/passward_update",
    name: "SettingPasswardUpdate",
    component: () => import(/* webpackChunkName: "passward_update" */ "@/views/setting/passward_update.vue"),
  },
  {
    path: "/setting/email_update",
    name: "SettingEmailUpdate",
    component: () => import(/* webpackChunkName: "email_update" */ "@/views/setting/email_update.vue"),
  },
  {
    path: "/diagnosis",
    name: "DiagnosisIndex",
    component: () => import(/* webpackChunkName: "diagnosis" */ "@/views/diagnosis/index.vue"),
  },
  {
    path: "/DiseaseManagement",
    name: "DiseaseManagement",
    component: () => import(/* webpackChunkName: "DiseaseManagement" */ "@/views/DiseaseManagement/index.vue"),
  },
  {
    path: "/symptom",
    name: "symptom",
    component: () => import(/* webpackChunkName: "symptom" */ "@/views/symptom/index.vue"),
  },
  {
    path: "/grade",
    name: "GradeIndex",
    component: () => import(/* webpackChunkName: "grade" */ "@/views/service/index.vue"),
  },
  {
    path: "/grade/service",
    name: "ServiceIndex",
    component: () => import(/* webpackChunkName: "service" */ "@/views/service/service.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { layout: false },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/home',
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Vue.$cookies.get("access_token");
  const userType = Vue.$cookies.get("user_type");

  if (to.name !== 'Login' && !isAuthenticated && to.name !== 'Register') {
    //alert('로그인 후 이용해주세요.');
    next({ name: 'Login' });
  } else if (to.name == 'MasterIndex' && (!isAuthenticated || userType !== "master")) {
    alert('해당 페이지 접근권한이 없습니다.');
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;