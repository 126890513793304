<template>
  <div class="header__wrap df">
    <!-- <div class="statue__wrap">
      <ul class="df" v-if="member != 1">
        <li>출석 현황</li>
        <li>
          <p class="th">전체 원생</p>
          <p class="td">{{ totalItems }}</p>
        </li>
        <li>
          <p class="th">출석 대상</p>
          <p class="td">{{ attend2 }}</p>
        </li>
        <li>
          <p class="th">출석</p>
          <p class="td">{{ attend }}</p>
        </li>
        <li>
          <p class="th">결석</p>
          <p class="td">{{ absence }}</p>
        </li>
        <li>
          <p class="th">조퇴</p>
          <p class="td">{{ leave }}</p>
        </li>
      </ul>
    </div> -->
    <div class="mobile-logo">
      <router-link to="/index">
        <p class="peachbite-zone__title"><img src="@/assets/images/PZ_logo.png"></p>
      </router-link>
    </div>
    <div class="tnb__wrap df">
      <ul class="list__line">
        <li>
          <button @click="handleLogout()" class="btn">
            <img src="~@/assets/images/header-tnb03.png" alt="로그아웃" />
          </button>
        </li>
        <li class="mobile-menu__button" @click="toggleMenu">
          <button>
            <i class="el-icon-close" v-if="menuOpen"></i>
            <i class="el-icon-s-fold" v-else></i>
          </button>
        </li>
      </ul>
      <div class="propile__box">
        <p class="name">{{ userData.user_name }}</p>
      </div>
    </div>

    <!-- 모바일 전용 메뉴 -->
    <transition name="slide">
      <div class="mobile-menu__box" v-if="menuOpen">
        <ul class="mobile-menu__list">
          <li class="mobile-menu__item" @click="menuOpen = false">
            <router-link to="/life/" active-class="active">
              <div class="menu__box">
                <div class="icon">
                  <div class="dft"><img src="~@/assets/images/menu-icon02.png" alt=""></div>
                  <div class="ov"><img src="~@/assets/images/menu-icon02-ov.png" alt=""></div>
                </div>
                <div class="title">{{ $t('menu-1') }}</div>
              </div>
            </router-link>
          </li>
          <li class="mobile-menu__item" @click="menuOpen = false">
            <router-link to="/diagnosis" active-class="active">
              <div class="menu__box">
                <div class="icon">
                  <div class="dft"><img src="~@/assets/images/notice_off.png" alt=""></div>
                  <div class="ov"><img src="~@/assets/images/notice_on.png" alt=""></div>
                </div>
                <div class="title">{{ $t('menu-2') }}</div>
              </div>
            </router-link>
          </li>

          <li class="mobile-menu__item" @click="menuOpen = false">
            <router-link to="/schedule/" active-class="active">
              <div class="menu__box">
                <div class="icon">
                  <div class="dft"><img src="~@/assets/images/menu-icon04.png" alt=""></div>
                  <div class="ov"><img src="~@/assets/images/menu-icon04-ov.png" alt=""></div>
                </div>
                <div class="title">{{ $t('menu-3') }}</div>
              </div>
            </router-link>
          </li>
          <li class="mobile-menu__item">
            <a @click="handleLogout()">
              <div class="menu__box">
                <div class="icon">
                  <img src="@/assets/images/icon_03.png">
                </div>
                <div class="title">{{ $t('logout') }}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </transition>

  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "header-view",
  components: {},
  data() {
    return {
      member: "",
      totalSearch: "",
      total: "",
      gender: "",
      neutered: "",
      menstruation: "",
      day: "",
      pickup: "",
      settings: "1",

      //출석현황
      totalItems: "",
      attend: "",
      attend2: "",
      noattend: "",
      absence: "",
      leave: "",
      userData: [],
      timestamp: "",
      menuOpen: false,
    };
  },
  mounted() {
    this.getUserInfo();
    this.timestamp = new Date();
    this.timestamp = this.formatDate(this.timestamp);
    this.member = this.$cookies.get('user_type');
  },


  methods: {
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    handleCollapse() {
      this.$nextTick(() => {
        this.$refs.menu.collapse();
      });
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },

    async checkAuthentication() {
      try {
        const response = await axios.get(
          "https://api.peachbite.co.kr/users/authcheck/",
          {
            headers: {
              Authorization: `${this.$cookies.get(
                "token_type"
              )} ${this.$cookies.get("access_token")}`,
              Accept: "application/json",
            },
          }
        );
        const access_token = response.data.access_token;
        const token_type = response.data.token_type;
        const expire_date = response.data.expire_date;
        const user_id = response.data.user_id;
        const user_type = response.data.user_type;

        this.$cookies.set("access_token", access_token);
        this.$cookies.set("token_type", token_type);
        this.$cookies.set("expire_date", expire_date);
        this.$cookies.set("user_id", user_id);
        this.$cookies.set("user_type", user_type);
      } catch (error) {
        //message box 로그아웃 메세지 이후 처음으로
        console.error("인증 실패", error);
        this.error = "인증 실패";
      }
    },

    handleLogout() {
      this.$confirm(this.$t('logout-alert'), this.$t('logout'), {
        cancelButtonText: this.$t('cancel'),
        confirmButtonText: this.$t('confirm'),
        type: "warning",
      }).then(() => {
        this.$message({
          type: "success",
          message: this.$t('logout-alert2'),
        });
        this.$cookies.keys().forEach(cookie => {
          this.$cookies.remove(cookie);
        });
        this.$router.push("/login"); // 로그인 페이지로 이동
      }).catch(() => {
      });
    },

    getUserInfo() {
      this.$axios
        .get(process.env.VUE_APP_USERSVIEW, {
          headers: {
            Authorization: `${this.$cookies.get(
              "token_type"
            )} ${this.$cookies.get("access_token")}`,
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.userData = response.data.data[0]
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },

    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
